import React from "react";
import config from "../../config";

import bgAlpen from "./images/bg-alpen.jpg";
import bgMilka from "./images/bg-milka.jpg";

class Card extends React.Component {
  constructor(props) {
    super(props);

    const [country] = config.countries.filter((country) => {
      return country.url === props.location.pathname;
    });

    // const { location, history } = this.props;
    const { history } = this.props;
    history.replace();

    // const hasPopup = !location.state || !location.state.fromList;

    this.state = {
      country,
      // isMuted: hasPopup,
      // hasPopup,
    };
  }

  render() {
    const project = process.env.REACT_APP_PROJECT;

    let bg = bgMilka;
    if (project === "alpen") {
      bg = bgAlpen;
    }

    let url = this.state.country.milkaUrl;
    if (project === "alpen") {
      url = this.state.country.alpenUrl;
    }

    return (
      <a href={url}>
        <div className="card" style={{ backgroundImage: `url("${bg}")` }}>
          {project === "milka" && <div className="card__logo" />}

          {/*<div className="card__image" />*/}
          <div className={`card__text card__text_${this.state.country.code}`}>
            <span className="v-hidden">{this.state.country.text}</span>
          </div>
        </div>
      </a>
    );
  }
}

export default Card;
