import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";
// import { zpUrls } from "../../config";

/* //global ym */

export default function Countries(props) {
  // const search = useLocation().search;
  // const product = new URLSearchParams(search).get("product");
  // console.log(product);

  // let url = zpUrls["p1"];
  // if (product === "p2") {
  //   url = zpUrls["p2"];
  // } else if (product === "p3") {
  //   url = zpUrls["p3"];
  // }

  // const music = document.getElementById("music");
  // try {
  //   music.pause();
  //   music.currentTime = 0;
  // } catch (e) {}
  // const project = process.env.REACT_APP_PROJECT;

  return (
    <div className="countries">
      <div className="countries__logo" />
      {config.countries.map((country, key) => {
        const className = `countries__link countries__link_${country.code}`;
        if (country.isExternal) {
          return (
            <a
              key={key}
              className={className}
              href={country.url}
              // onClick={() => {
              //   ym(86083710, "reachGoal", country.code);
              //   return true;
              // }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="v-hidden">{country.label}</span>
            </a>
          );
        } else {
          return (
            <Link
              key={key}
              className={className}
              // onClick={() => {
              //   ym(86083710, "reachGoal", country.code);
              //   return true;
              // }}
              to={{
                pathname: country.url,
                state: { fromList: true, orange: 2 },
              }}
            >
              <span className="v-hidden">{country.label}</span>
            </Link>
          );
        }
      })}
    </div>
  );
}
