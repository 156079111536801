// export const zpUrls = {
//   // p1: "https://new-milka.arweb.app/?zid=z/kFCp1c&rs=0&menu=0&toolbar=0",
//   p1: "https://www.mondelezinternational.com/Russia",
//   // p2: "https://new-milka.arweb.app/?zid=z/1kkn1c&rs=0&menu=0&toolbar=0",
//   p2: "https://promo.milka.ru/",
//   // p3: "https://new-milka.arweb.app/?zid=z/ZAbk1c&rs=0&menu=0&toolbar=0",
//   p3: "https://alpengold.me/",
// };

const ru = {
  label: "Россия",
  code: "ru",
  url: "/ru",
  isExternal: false,
  milkaUrl: "https://milka.ru/",
  alpenUrl: "http://alpengold.me/",
};

const kz = {
  label: "Казахстан",
  code: "kz",
  url: "/kz",
  isExternal: false,
  milkaUrl: "https://www.instagram.com/milka_kazakhstan/?hl=ru",
  alpenUrl: "https://www.instagram.com/alpengold_kazakhstan/?hl=ru",
};

const ge = {
  //Грузия
  label: "საქართველო",
  text: "",
  code: "ge",
  url: "/ge",
  isExternal: false,
  milkaUrl: "https://www.instagram.com/milka_georgia/",
  alpenUrl: "https://www.instagram.com/alpen_gold_georgia/",
};

const az = {
  //Азербайджан
  label: "Azərbaycan",
  text: "",
  code: "az",
  url: "/az",
  isExternal: false,
  milkaUrl: "https://www.instagram.com/milka_azerbaijan/",
  alpenUrl: "https://www.instagram.com/alpengold_azerbaijan/",
};

const uz = {
  //Узбекистан
  label: "O'zbekiston",
  text: "",
  code: "uz",
  url: "/uz",
  isExternal: false,
  milkaUrl: "https://www.instagram.com/milka_uzbekistan/?utm_medium=copy_link",
  alpenUrl: "https://instagram.com/alpengold_uzbekistan?utm_medium=copy_link",
};

// eslint-disable-next-line no-unused-vars
const kg = {
  //Кыргызстан
  label: "Кыргызстан",
  text: "",
  code: "kg",
  url: "/kg",
  isExternal: false,
  milkaUrl: "",
  alpenUrl: "",
};

const am = {
  //Армения
  label: "Հայաստան",
  text: "",
  code: "am",
  url: "/am",
  isExternal: false,
  milkaUrl: "",
  alpenUrl: "",
};

const mn = {
  //Монголия
  label: "Монгол",
  text: "",
  code: "mn",
  url: "/mn",
  isExternal: false,
  milkaUrl: "https://www.facebook.com/Milka-Mongolia-101357404744766/",
  alpenUrl: "https://www.facebook.com/Alpen-Gold-Mongolia-110013467044496/",
};

const by = {
  //Беларусь
  label: "Беларусь",
  text: "",
  code: "by",
  url: "/by",
  isExternal: false,
  milkaUrl: "https://milka.ru/",
  alpenUrl: "http://alpengold.me/",
};

const config = {
  countries: [],
};

if (process.env.REACT_APP_PROJECT === "milka") {
  config.countries.push(ru, kz, ge, az, kg, uz, am, mn, by);
} else {
  config.countries.push(ru, kz, ge, az, uz, am, mn, by);
}

export default config;
